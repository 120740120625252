import React from "react"
import { Helmet } from "react-helmet"
import { Script, ScriptStrategy } from "gatsby"

//  import "../../../static/bootstrap-icons-1.2.1/font/bootstrap-icons.css"

export const getWebFontUris = availbleFonFamily => {
  let fontMap = {}
  if (!availbleFonFamily) return

  availbleFonFamily.forEach(f => {
    let temp = f?.split("/")
    if (fontMap[temp[0]] != null) {
      fontMap[temp[0]].push(f)
    } else {
      fontMap[temp[0]] = []
      fontMap[temp[0]].push(f)
    }
  })

  let rslt = []
  Object.keys(fontMap).forEach(f => {
    let font = new Set()
    let fontWt = new Set()
    let fontWtItalic = new Set()
    let fontSubs = new Set()
    let stle = new Set()
    fontMap[f].forEach(el => {
      let temp = el?.split("/")
      font.add(temp[0])
      fontWt.add(temp[1])
      fontWtItalic.add(temp[1] + "italic")
      fontSubs.add(temp[2])
      stle.add(temp[3])
    })
    rslt.push(
      `"${
        f + ":" + [...fontWt] + "," + [...fontWtItalic] + ":" + [...fontSubs]
      }"`
    )
  })

  return rslt
}

export default function PrimeAgileHelmet({ pageContext, LayoutCSS }) {
  const websiteCss = pageContext.websiteCSS
  const layoutSetCss = pageContext.layoutSetCss
  const websiteJavascript = pageContext.websiteJavascript
  const jsonLd = pageContext.jsonLd
  const favicon = pageContext.favicon
  const title = pageContext.title
  const fontScheme = pageContext.fontScheme
  const googleAnalyticsCode = pageContext.googleAnalyticsCode
  const metaTitle = pageContext.metaTitle
  const pathname = pageContext.url
  const metaDescription = pageContext.description
  const coverImage = pageContext.coverImageUrl
  const locales = pageContext.locales

  const protocol = window.location.protocol

  function isUrlValid(url) {
    // validate if url is correct using regex
    const regex =
      /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!]))?/
    if (regex.test(url)) {
      return true
    }
    return false
  }

  const fonts =
    fontScheme?.availableFontFamily &&
    getWebFontUris(fontScheme?.availableFontFamily)

  const googleTag =
    googleAnalyticsCode &&
    googleAnalyticsCode !== "string" &&
    googleAnalyticsCode.split(",")

  // React.useEffect(() => {
  //   const script = document.createElement("script")
  //   const fonts = getWebFontUris(fontScheme.availableFontFamily)

  //   window.setTimeout(() => {
  //     script.textContent = `WebFont && WebFont.load({
  //       google: { families: [${fonts}] },
  //     })`
  //     script.defer = true
  //     if (script && fonts) {
  //       document.body.appendChild(script)
  //     }
  //   }, 1500)

  //   return () => {
  //     try {
  //       document.body.removeChild(script)
  //     } catch (error) {}
  //   }
  // }, [])

  React.useEffect(() => {
    let timer
    if (googleTag && googleTag[0]) {
      const googleAnalyticsCodeScript = document.createElement("script")
      googleAnalyticsCodeScript.textContent = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleTag[0]}');`
      document.head.appendChild(googleAnalyticsCodeScript)
      googleAnalyticsCodeScript.async = true
    }
  }, [])

  // React.useEffect(() => {
  //   const script = document.createElement("script")
  //   script.src = websiteJavascript
  //   script.async = true
  //   document.body.appendChild(script)
  //   return () => {
  //     try {
  //       document.body.removeChild(script)
  //     } catch (error) {}
  //   }
  // }, [])

  const canonical = pathname
    ? `${process.env.GATSBY_BUILDSITE}${pathname}`
    : null

  React.useEffect(() => {
    var image = document.createElement("meta")
    image.setAttribute("property", "og:image")
    image.content = coverImage
    const head = document.getElementsByTagName("head")[0]
    head.insertBefore(image, head.firstChild)
  }, [])

  return (
    <Helmet
      htmlAttributes={{
        lang: "en-US",
      }}
      title={pageContext.title}
      titleTemplate={`%s | ${process.env.GATSBY_BUILDSITE.replace(".com", "")}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
        },
        {
          property: `og:title`,
          content: metaTitle?.phrase || title,
        },
        {
          property: `og:image:url`,
          content: coverImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: process.env.GATSBY_BUILDSITE.replace(".com", ""),
        },
        {
          name: `twitter:title`,
          content: metaTitle?.phrase || title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: coverImage,
        },
      ].concat([
        {
          name: "twitter:card",
          content: "summary",
        },
      ])}
    >
      {googleTag && googleTag[0] && (
        <script
          src={`https://www.googletagmanager.com/gtag/js?id=${googleTag[0]}`}
          async
        />
      )}

      <title>{title}</title>

      <meta property="og:image" content={coverImage} />

      <meta property="og:image:height" content="630"></meta>
      <meta property="og:image:width" content="1200"></meta>
      <meta
        property="og:image:alt"
        content={pageContext?.CoverImagePicture?.coverPictureAltText}
      ></meta>

      <meta name="description" content={metaDescription} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content={"website"} />
      <meta property="og:url" content={`https://www.demo.primeagile.com`} />

      {/* {googleTag &&
        googleAnalyticsCode?.split(",").map(code => {
          return (
            <script
              key={code}
              src={`https://www.googletagmanager.com/gtag/js?id=${code}`}
              async
            />
          )
        })} */}

      <meta charSet="utf-8" />
      <meta
        name="keywords"
        content={
          pageContext?.keyPhrase?.length > 0 &&
          pageContext?.keyPhrase?.map(phrase => phrase.phrase)?.join(",")
        }
      />
      {/* {locales &&
        locales.map(locale => {
          return (
            <link
              key={locale}
              rel="alternate"
              hreflang={locale.toLowerCase()}
              href={`${protocol}//${
                process.env.GATSBY_BUILDSITE
              }/${locale.toLowerCase()}${pathname}`}
            />
          )
        })} */}

      <link
        rel="alternate"
        hreflang="x-default"
        href={`${protocol}//${process.env.GATSBY_BUILDSITE}${pathname}`}
      />

      {/* <meta name="description" content={pageContext.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {(metaTitle && <meta property="og:title" content={metaTitle.phrase} />) ||
        (title && <meta property="og:title" content={title} />)}
      <meta property="og:description" content={pageContext.description} />
      <meta property="og:type" content={"website"} />
      <meta
        property="og:url"
        content={`https://www.${process.env.GATSBY_BUILDSITE}`}
      /> */}

      <script type="application/ld+json" async>
        {jsonLd ? `${JSON.stringify(jsonLd)}` : ""}
      </script>
      {/* <link
        fetchpriority="high"
        rel="preload"
        href={pageContext.websiteCSS}
        as="style"
        type="text/css"
        crossorigin="anonymous"
      ></link> */}
      <link rel="icon" type="image/x-icon" href={favicon} />
      {isUrlValid(favicon) ? (
        <link rel="shortcut icon" type="image/jpg" href={favicon} />
      ) : (
        "https://www.admin.primeagile.com/favicon.ico"
      )}
      {/* {layoutSetCss && (
        <link
          rel="stylesheet preload"
          data-primeagile-layout
          type="text/css"
          href={layoutSetCss}
          // async
          fetchpriority="high"
          crossorigin
          as="style"
          defer
        />
      )}
      {websiteCss && (
        <link
          rel="stylesheet preload"
          data-primeagile-website
          type="text/css"
          href={websiteCss}
          // defer
          fetchpriority="high"
          crossorigin
          as="style"
          defer
        />
      )} */}
      {/* {jsonLd && (
        <Script
          src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
          strategy="off-main-thread"
        >
          {`
          ${JSON.stringify(jsonLd)}}
           `}
        </Script>
      )} */}
      {/* <script
        defer
        src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
        rel="dns-prefetch"
      ></script> */}
      {/* <script src="https://cdn.logwork.com/widget/countdown.js"></script> */}
    </Helmet>
  )
}
